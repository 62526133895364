import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import styles from './styles/Forsale.module.css';

const ForSale = () => {
	return (
		<div className={styles.background}>
			<Container>
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<Row>
					<Col>
						<h1 className={styles.forSaleHeader}>
							<b>For Sale</b>
						</h1>
						<hr className={styles.divider} />
					</Col>
				</Row>
				<Row>
					<Col
						xs={12}
						sm={12}
						md={{ span: 6, offset: 3 }}
						lg={{ span: 6, offset: 3 }}
					>
						<div className='card'>
							<br />
							<div className='card-title'>
								<h3 class={styles.forSaleHeaderSmall}>JET SKI DOCK FOR RENT</h3>
							</div>
							<div className='card-body text-center'>
								<h5 className={styles.forSaleDesc}>
									Available to rent for the 2025 season <br />
									(Plymouth Shore members only)
								</h5>
								<p className={styles.forSaleDesc}>Jet Ski Dock #3</p>
								<p className={styles.forSaleDesc}>Rate: $500</p>
								<p className={styles.forSaleDesc}>
									Please contact Dennis Casey for details
								</p>
								<p className={styles.forSaleDesc}>Ph: 440-409-3831</p>
								<p className={styles.blue}>[Posted on 1/10/25]</p>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default ForSale;
