import React from 'react';
import { Container, Card, Row, Col, Image } from 'react-bootstrap';
import styles from './styles/Loggedin.module.css';

const Loggedin = () => {
	return (
		<Container>
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<h3 className={styles.memberTitle}>Member Login</h3>
			<hr />
			<Row>
				<Col sm='12' md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }}>
					<Card className={styles.noticeCard}>
						<Card.Body>
							<Row>
								<Col sm='12' md='6' lg='6'>
									<Image
										src='imgs/important_notice.png'
										alt='important notice image'
										className={styles.notice}
									/>
								</Col>
								<Col sm='12' md='6' lg='6'>
									<p className='cardText'>
										<b>
											Effective 9-1-20, send Spring & Fall payments to the NEW
											address below:
										</b>
									</p>
									<p>2220 W. Cook Road Mansfield, OH 44906</p>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<br />
			<Row>
				<Col className='col-sm-12 col-md-12 col-lg-12'>
					<Card className='mt-5 mb-2'>
						<Card.Body>
							<h3 className={styles.documentTitle}>Email Annoucements</h3>
							<Row>
								<Col sm='12' md='6' lg={6}>
									<ul className={styles.documentList}>
										<a href='fall_2024_letter_9_25_24.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Email Announcement - Fall 2024 Letter <br /> (9-25-24)
											</li>
										</a>
									</ul>
								</Col>
								<Col sm='12' md='6' lg={6}>
									<ul className={styles.documentList}>
										<a href='2024_fall_fees.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>Email Announcement - Fall 2024 Fees</li>
										</a>
									</ul>
								</Col>
								<Col sm='12' md='6' lg={6}>
									<ul className={styles.documentList}>
										<a href='labor_day_weekend_8_27_24.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Email Announcement - Labor Day Weekend <br /> (8-27-24)
											</li>
										</a>
									</ul>
								</Col>
								<Col sm='12' md='6' lg={6}>
									<ul className={styles.documentList}>
										<a
											href='email_announcement_4th_of_july.pdf'
											target='_blank'
										>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Email Announcement - 4th of July <br /> (7-1-24)
											</li>
										</a>
									</ul>
								</Col>

								<Col sm='12' md='6' lg={6}>
									<ul className={styles.documentList}>
										<a href='pet_owners.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Email Announcement - Pet Owners <br /> (6-3-23)
											</li>
										</a>
									</ul>
								</Col>
								<Col sm='12' md='6' lg={6}>
									<ul className={styles.documentList}>
										<a
											href='email_announcment_overflow_parking.pdf'
											target='_blank'
										>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Email Announcement - Overflow Parking <br /> (5-27-23)
											</li>
										</a>
									</ul>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col className='col-sm-12 col-md-12 col-lg-12'>
					<Card className='mt-5 mb-5'>
						<Card.Body>
							<h3 className={styles.documentTitle}>Documents</h3>
							<Row>
								<Col sm='12' md='6' lg={{ span: 6, offset: 1 }}>
									<ul className={styles.documentList}>
										<a href='spring_member_meeting_minutes.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Spring Member Meeting Minutes <br /> (5-26-24)
											</li>
										</a>
										<a href='balance_sheet_11_30_24.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Balance Sheet <br /> (11-30-24)
											</li>
										</a>

										<a
											href='spring_meeting_presentation_2023.pdf'
											target='_blank'
										>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Spring Member Meeting Presentation <br /> (5-28-2023)
											</li>
										</a>

										<a href='fall_meeting22.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Fall Member Meeting Presentation <br /> (9-4-2022)
											</li>
										</a>

										<a href='late_policy_new.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Late Policy <br /> (9-15-2021)
											</li>
										</a>
										<a href='fall_presentation_2021.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Fall Member Meeting Presentation <br /> (9-5-2021)
											</li>
										</a>
										<a href='fall_minutes_2021.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Fall Member Meeting Minutes <br /> (9-5-2021)
											</li>
										</a>
										<a href='5_30_21.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Spring Member Meeting Minutes <br /> (5-30-21)
											</li>
										</a>

										<a href='park_meeting.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Spring Member Meeting Minutes <br /> (5-27-2020)
											</li>
										</a>
									</ul>
								</Col>
								<Col>
									<ul className={styles.documentList}>
										<a href='member_directory_august_2024.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Member Directory <br /> (August 2024)
											</li>
										</a>
										<a href='board_of_directors.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Current Board of Directors <br />
											</li>
										</a>
										<a href='rules_regulations_march_2020.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Rules & Regulations
												<br />
											</li>
										</a>
										<a href='pdf3.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Proprietary Lot Lease <br />
											</li>
										</a>
										<a href='pdf4.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Proprietary Marina Lease <br />
											</li>
										</a>
										<a href='pdf2.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Operating Agreement <br />
											</li>
										</a>
										<a href='visitors.pdf' target='_blank'>
											<Image
												src='imgs/pdf-icon.png'
												className={styles.pdf}
											></Image>
											<li>
												Registration / Visitor Form <br />
											</li>
										</a>
									</ul>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Loggedin;
